﻿@import './_variables.scss';

a, a:link, a:visited {
    color: #0063C2;
}

/* TABLES */
/* Vertically align table cells to middle */
table.table-vertical-middle {
    display: table;
}

table.table-vertical-middle td:first-child {
    display: table-cell;
    vertical-align: middle;
}

table.table-vertical-middle td:last-child {
    display: table-cell;
    vertical-align: middle;
}

table.table-vertical-middle label {
    margin: 0;
}

.table-super-condensed > thead > tr > th,
.table-super-condensed > tbody > tr > th,
.table-super-condensed > tfoot > tr > th,
.table-super-condensed > thead > tr > td,
.table-super-condensed > tbody > tr > td,
.table-super-condensed > tfoot > tr > td {
    padding: 2px;
}

.table-super-condensed > tbody > tr > td, .table-super-condensed > thead > tr > th, .table-super-condensed > thead > tr > td {
    white-space: nowrap;
}
/* END TABLES */

input[type='checkbox'], div.checkbox {
    cursor: pointer;
}

div.checkbox {
    margin: inherit;
}

/* Customized backgrounds */
div.bg-primary, span.bg-primary, .bg-primary {
    background-color: #337AB7;
}

div.bg-danger, span.bg-danger, .bg-danger {
    background-color: #D9534F;
}

div.bg-success, span.bg-success, .bg-success {
    background-color: #5CB85C;
}

div.bg-info, span.bg-info, .bg-info {
    background-color: #5BC0DE;
}

div.bg-warning, span.bg-warning, .bg-warning {
    background-color: #F0AD4E;
}
/* Linear gradient backgrounds */
div.bg-primary-lg, span.bg-primary-lg, .bg-primary-lg {
    background-color: #337AB7;
    background-image: linear-gradient(#337AB7 0px, #265A88 100%);
}

div.bg-danger-lg, span.bg-danger-lg, .bg-danger-lg {
    color: #D9534F;
    background-image: linear-gradient(#D9534F 0px, #C12E2A 100%);
}

div.bg-success-lg, span.bg-success-lg, .bg-success-lg {
    color: #5CB85C;
    background-image: linear-gradient(#5CB85C 0px, #419641 100%);
}

div.bg-info-lg, span.bg-info-lg, .bg-info-lg {
    color: #5BC0DE;
    background-image: linear-gradient(#5BC0DE 0px, #2AABD2 100%);
}

div.bg-warning-lg, span.bg-warning-lg, .bg-warning-lg {
    color: #F0AD4E;
    background-image: linear-gradient(#F0AD4E 0px, #EB9316 100%);
}
/* Borders */
div.b-primary, span.b-primary, .b-primary {
    border: 1px solid #337AB7;
}

div.b-danger, span.b-danger, .b-danger {
    border: 1px solid #D9534F;
}

div.b-success, span.b-success, .b-success {
    border: 1px solid #5CB85C;
}

div.b-info, span.b-info, .b-info {
    border: 1px solid #5BC0DE;
}

div.b-warning, span.b-warning, .b-warning {
    border: 1px solid #F0AD4E;
}

// extend callouts bootstrap
.bs-callout {
    padding: 20px;
    margin: 20px 0;
    border: 1px solid #eee;
    border-left-width: 5px;
    border-radius: 3px;
}

.bs-callout h4 {
    margin-top: 0;
    margin-bottom: 5px;
}

.bs-callout p:last-child {
    margin-bottom: 0;
}

.bs-callout code {
    border-radius: 3px;
}

.bs-callout + .bs-callout {
    margin-top: -5px;
}

.bs-callout-default {
    border-left-color: #777;
}

.bs-callout-default h4 {
    color: #777;
}

.bs-callout-primary {
    border-left-color: #428bca;
}

.bs-callout-primary h4 {
    color: #428bca;
}

.bs-callout-success {
    border-left-color: #5cb85c;
}

.bs-callout-success h4 {
    color: #5cb85c;
}

.bs-callout-danger {
    border-left-color: #d9534f;
}

.bs-callout-danger h4 {
    color: #d9534f;
}

.bs-callout-warning {
    border-left-color: #f0ad4e;
}

.bs-callout-warning h4 {
    color: #f0ad4e;
}

.bs-callout-info {
    border-left-color: #5bc0de;
}

.bs-callout-info h4 {
    color: #5bc0de;
}

.input-group-xs > .form-control,
.input-group-xs > .input-group-addon,
.input-group-xs > .input-group-btn > .btn {
    height: 22px;
    padding: 1px 5px;
    font-size: 12px;
    line-height: 1.5;
}


.btn-file {
    position: relative;
    overflow: hidden;
}

.btn-file input[type=file] {
    position: absolute;
    top: 0;
    right: 0; 
    min-width: 100%;
    min-height: 100%;
    font-size: 100px;
    text-align: right;
    filter: alpha(opacity=0);
    opacity: 0;
    outline: none;
    background: white;
    cursor: inherit;
    display: block;
}

[hidden] {
    display: none !important;
}

.pagination-ys {
    /*display: inline-block;*/
    padding-left: 0;
    margin: 20px 0;
    border-radius: 4px;
}

.pagination-ys table > tbody > tr > td {
    display: inline;
}

.pagination-ys table > tbody > tr > td > a,
.pagination-ys table > tbody > tr > td > span {
    position: relative;
    float: left;
    padding: 8px 12px;
    line-height: 1.42857143;
    text-decoration: none;
    color: #337ab7;
    background-color: #ffffff;
    border: 1px solid #dddddd;
    margin-left: -1px;
}

.pagination-ys table > tbody > tr > td > span {
    position: relative;
    float: left;
    padding: 8px 12px;
    line-height: 1.42857143;
    text-decoration: none;
    margin-left: -1px;
    z-index: 2;
    color: #aea79f;
    background-color: #f5f5f5;
    border-color: #dddddd;
    cursor: default;
}

.pagination-ys table > tbody > tr > td:first-child > a,
.pagination-ys table > tbody > tr > td:first-child > span {
    margin-left: 0;
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px;
}

.pagination-ys table > tbody > tr > td:last-child > a,
.pagination-ys table > tbody > tr > td:last-child > span {
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px;
}

.pagination-ys table > tbody > tr > td > a:hover,
.pagination-ys table > tbody > tr > td > span:hover,
.pagination-ys table > tbody > tr > td > a:focus,
.pagination-ys table > tbody > tr > td > span:focus {
    color: #97310e;
    background-color: #eeeeee;
    border-color: #dddddd;
}

.btn-default {
    background-image: linear-gradient(#FFF, #EFEFEF) !important;
    background-color: #f0f1f3 !important;
    color: #000 !important;
    border: 1px solid #999 !important;
}

table.table tr > th{
    a, a:link, a:visited{
        color:#000;
        text-decoration:underline;
    }
}





.container {
    @media (min-width: 1400px) {
        max-width: 1340px;
    }

    @media (min-width: 1600px) {
        max-width: 1540px;
    }

    @media (min-width: 1800px) {
        max-width: 1640px;
    }
}

.nav-item{
    padding-left:$gutter/2 !important;
}

nav {
    .dropdown-item {
        background-color: transparent;
        &:hover{
            background-color:#222 !important;
        }
    }

    .dropdown-menu {
        background: linear-gradient(to right, #555 0%, #666 100%);
    }
}

.breadcrumb {
    background-color: #f5f5f5;
    padding: 8px 12px;
}
.navbar{
    padding: 6px 12px;
}


.input-xs {
    height: 22px;
    padding: 2px 5px;
    font-size: 12px;
    line-height: 1.5;
    border-radius: 3px;
}


.input-group {
    .btn {
        padding: 0.25rem 0.5rem;
        font-size: 0.875rem;
        line-height: 1.5;
        border-radius: 0.2rem;
    }
    .btn-xs{
        @extend .btn-xs;
    }
}

/*.btn-md {
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
}

.btn-lg {
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.2rem;
}*/

.form-control {
    height: calc(1.8125rem + 2px);
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.2rem;
}

.input-group-text {
    height: calc(1.8125rem + 2px);
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.2rem;
}

.input-group-text-xs, .input-group-xs input-group-text, .input-group-xs btn, .input-group-xs input, .btn-xs {
    height: 22px;
    padding: 2px 5px;
    font-size: 12px;
    line-height: 1.5;
    border-radius: 3px;
}