﻿@import '../../_variables.scss';

.weborder_inc {
    background-color: purple;
}

div#web-order {
    .m-top {
        /*margin-top: $gutter/2;*/
        margin-top:12px;
    }
    .m-bot{
        margin-bottom:12px;
    }
    .row{
        margin-right:-12px;
        margin-left:-12px;
    }
    .row > div{
        padding-left:12px;
        padding-right:12px;
    }

    .web-order-heading {
        font-size: 40px;
        font-weight: 700;

        .small {
            font-weight: 700;
            font-size: 22px;
        }

        .copy {
            font-size: 22px;
            cursor: copy;
            padding-left: 4px;
        }
    }

    .copy {
        color: #999;

        &:hover {
            cursor: pointer;
            color: #222;
        }
    }

    table.table-line-items {
        tr > th {
            font-size: 11px;
        }

        tr > td {
            font-size: 11px;
        }

        .itemid {
            font-size: 14px;
        }
    }

    table.table-kvp {
        tr, tr > td {
            background-color: transparent;
            border: none;
            padding: 0;
            margin: 0;
            text-align: left;
        }

        margin: 0;
        padding: 0;
    }

    table.table-custom-head > tbody > tr:first-child {
        background-image: linear-gradient(to top, #444 0%, #444 60%, #666 100%);
        font-family: 'Roboto', Verdana, Arial, sans-serif;

        > th {
            color: #EAEAEA;
            font-size: 13px;
            border-color: #555;
            font-weight: 400;
            text-align: center;
            padding-top: 2px;
            padding-bottom: 2px;
        }
    }

    table.table-custom-footer > tbody > tr:last-child {
        background-image: linear-gradient(to top, #444 0%, #444 60%, #666 100%);
        font-family: 'Roboto', Verdana, Arial, sans-serif;

        > td {
            color: #EAEAEA;
            font-size: 13px;
            border-color: #555;
            font-weight: 400;
            text-align: center;
            padding-top: 1px;
            padding-bottom: 1px;
        }
    }

    .fa-lock {
        color: #222;
    }

    table.table-address {
        td {
            margin: 0;
            padding: 2px 4px;
        }
    }

    table.table-order-info {
        tr td:first-child {
            text-align: right;
            font-weight: 700;
        }

        tr td:not(:first-child) {
            text-align: left;
            padding-left: 6px;
        }
    }

    .dotted-border {
        border: 1px dotted #999;
    }

    .m-left-6 {
        margin-left: 6px;
    }

    .fa-14 {
        font-size: 14px;
    }

    .border-sides {
        border-left: 1px solid #A3A3A3;
        border-right: 1px solid #A3A3A3;
    }

    .border-bottom {
        border-bottom: 1px solid #A3A3A3 !important;
    }
    table.table-order-history{
        tr td:first-child{
            width:160px;
        }
        tr td:last-child{
            white-space:normal;
            word-break:break-all;
        }
    }
    table.table-tech{
        tr td:first-child{
            width:140px;
        }
    }
}


.orders-inc {
    margin-top: 12px;

    .m-bot {
        margin-bottom: 12px;
    }
}