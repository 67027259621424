.awesomplete {
  display: block;
  font-family: "proxima nova", "Helvetica Neue", Helvetica, Helvetica, Arial, sans-serif; }

.awesomplete > ul {
  background: rgba(255, 255, 255, 0.95); }

.awesomplete > ul > li {
  color: #222222;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  font-size: 16px; }

.awesomplete > ul > li[aria-selected="true"] {
  background: none;
  font-weight: inherit;
  color: #000;
  background-color: #EAEAEA; }

.awesomplete mark {
  background: none;
  font-weight: 700;
  padding: 0; }

.awesomplete li[aria-selected="true"] mark {
  background: none;
  font-weight: 700; }

.awesomplete > ul > li:hover, .awesomplete li:hover mark {
  background: none;
  background-color: #EAEAEA;
  color: #000; }

/* reinstitute initial .form-control border radius when nested within .awesomeplete */
.input-group .awesomplete .form-control {
  border-radius: 4px; }

/* reinstitute .border-radius squaring within form groups */
.input-group .awesomplete:first-child .form-control {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group .awesomplete:not(:first-child) .form-control {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.input-group .awesomplete:not(:first-child):not(:last-child) .form-control {
  border-radius: 0; }

.input-group-lg .awesomplete .form-control {
  height: 46px;
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.3333333; }

/**
 * bootstrap's input groups modify the float & z-index of form-controls, this allows awesomplete
 * to assume the position of the form-control and reset the input
 */
.input-group div.awesomplete {
  position: relative;
  z-index: 2;
  float: left;
  width: 100%;
  margin-bottom: 0; }

.input-group div.awesomplete > input {
  float: none; }

.input-group div.awesomplete > ul {
  z-index: 3;
  /* bootstrap sets .input-group .form-control to 2 */ }

.badge-lg {
  font-size: 14px;
  font-family: 'Roboto', Verdana, Arial, sans-serif;
  padding: 8px 16px; }

#toast-container > div {
  opacity: 0.975; }

table.dxeReadOnly_MaterialCompact, table.dxeReadOnly_MaterialCompact input, table.dxeReadOnly_MaterialCompact textarea, table.dxeReadOnly_MaterialCompact select {
  background-color: #ccc !important; }

div.dxmLite_MaterialCompact.dxm-ltr > div {
  padding: 4px 12px !important; }

div.dxgvSearchPanel_MaterialCompact {
  padding: 6px 12px !important; }

.tooltip-inner {
  text-align: left; }

a, a:link, a:visited {
  color: #0063C2; }

/* TABLES */
/* Vertically align table cells to middle */
table.table-vertical-middle {
  display: table; }

table.table-vertical-middle td:first-child {
  display: table-cell;
  vertical-align: middle; }

table.table-vertical-middle td:last-child {
  display: table-cell;
  vertical-align: middle; }

table.table-vertical-middle label {
  margin: 0; }

.table-super-condensed > thead > tr > th,
.table-super-condensed > tbody > tr > th,
.table-super-condensed > tfoot > tr > th,
.table-super-condensed > thead > tr > td,
.table-super-condensed > tbody > tr > td,
.table-super-condensed > tfoot > tr > td {
  padding: 2px; }

.table-super-condensed > tbody > tr > td, .table-super-condensed > thead > tr > th, .table-super-condensed > thead > tr > td {
  white-space: nowrap; }

/* END TABLES */
input[type='checkbox'], div.checkbox {
  cursor: pointer; }

div.checkbox {
  margin: inherit; }

/* Customized backgrounds */
div.bg-primary, span.bg-primary, .bg-primary {
  background-color: #337AB7; }

div.bg-danger, span.bg-danger, .bg-danger {
  background-color: #D9534F; }

div.bg-success, span.bg-success, .bg-success {
  background-color: #5CB85C; }

div.bg-info, span.bg-info, .bg-info {
  background-color: #5BC0DE; }

div.bg-warning, span.bg-warning, .bg-warning {
  background-color: #F0AD4E; }

/* Linear gradient backgrounds */
div.bg-primary-lg, span.bg-primary-lg, .bg-primary-lg {
  background-color: #337AB7;
  background-image: linear-gradient(#337AB7 0px, #265A88 100%); }

div.bg-danger-lg, span.bg-danger-lg, .bg-danger-lg {
  color: #D9534F;
  background-image: linear-gradient(#D9534F 0px, #C12E2A 100%); }

div.bg-success-lg, span.bg-success-lg, .bg-success-lg {
  color: #5CB85C;
  background-image: linear-gradient(#5CB85C 0px, #419641 100%); }

div.bg-info-lg, span.bg-info-lg, .bg-info-lg {
  color: #5BC0DE;
  background-image: linear-gradient(#5BC0DE 0px, #2AABD2 100%); }

div.bg-warning-lg, span.bg-warning-lg, .bg-warning-lg {
  color: #F0AD4E;
  background-image: linear-gradient(#F0AD4E 0px, #EB9316 100%); }

/* Borders */
div.b-primary, span.b-primary, .b-primary {
  border: 1px solid #337AB7; }

div.b-danger, span.b-danger, .b-danger {
  border: 1px solid #D9534F; }

div.b-success, span.b-success, .b-success {
  border: 1px solid #5CB85C; }

div.b-info, span.b-info, .b-info {
  border: 1px solid #5BC0DE; }

div.b-warning, span.b-warning, .b-warning {
  border: 1px solid #F0AD4E; }

.bs-callout {
  padding: 20px;
  margin: 20px 0;
  border: 1px solid #eee;
  border-left-width: 5px;
  border-radius: 3px; }

.bs-callout h4 {
  margin-top: 0;
  margin-bottom: 5px; }

.bs-callout p:last-child {
  margin-bottom: 0; }

.bs-callout code {
  border-radius: 3px; }

.bs-callout + .bs-callout {
  margin-top: -5px; }

.bs-callout-default {
  border-left-color: #777; }

.bs-callout-default h4 {
  color: #777; }

.bs-callout-primary {
  border-left-color: #428bca; }

.bs-callout-primary h4 {
  color: #428bca; }

.bs-callout-success {
  border-left-color: #5cb85c; }

.bs-callout-success h4 {
  color: #5cb85c; }

.bs-callout-danger {
  border-left-color: #d9534f; }

.bs-callout-danger h4 {
  color: #d9534f; }

.bs-callout-warning {
  border-left-color: #f0ad4e; }

.bs-callout-warning h4 {
  color: #f0ad4e; }

.bs-callout-info {
  border-left-color: #5bc0de; }

.bs-callout-info h4 {
  color: #5bc0de; }

.input-group-xs > .form-control,
.input-group-xs > .input-group-addon,
.input-group-xs > .input-group-btn > .btn {
  height: 22px;
  padding: 1px 5px;
  font-size: 12px;
  line-height: 1.5; }

.btn-file {
  position: relative;
  overflow: hidden; }

.btn-file input[type=file] {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 100%;
  min-height: 100%;
  font-size: 100px;
  text-align: right;
  filter: alpha(opacity=0);
  opacity: 0;
  outline: none;
  background: white;
  cursor: inherit;
  display: block; }

[hidden] {
  display: none !important; }

.pagination-ys {
  /*display: inline-block;*/
  padding-left: 0;
  margin: 20px 0;
  border-radius: 4px; }

.pagination-ys table > tbody > tr > td {
  display: inline; }

.pagination-ys table > tbody > tr > td > a,
.pagination-ys table > tbody > tr > td > span {
  position: relative;
  float: left;
  padding: 8px 12px;
  line-height: 1.42857143;
  text-decoration: none;
  color: #337ab7;
  background-color: #ffffff;
  border: 1px solid #dddddd;
  margin-left: -1px; }

.pagination-ys table > tbody > tr > td > span {
  position: relative;
  float: left;
  padding: 8px 12px;
  line-height: 1.42857143;
  text-decoration: none;
  margin-left: -1px;
  z-index: 2;
  color: #aea79f;
  background-color: #f5f5f5;
  border-color: #dddddd;
  cursor: default; }

.pagination-ys table > tbody > tr > td:first-child > a,
.pagination-ys table > tbody > tr > td:first-child > span {
  margin-left: 0;
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px; }

.pagination-ys table > tbody > tr > td:last-child > a,
.pagination-ys table > tbody > tr > td:last-child > span {
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px; }

.pagination-ys table > tbody > tr > td > a:hover,
.pagination-ys table > tbody > tr > td > span:hover,
.pagination-ys table > tbody > tr > td > a:focus,
.pagination-ys table > tbody > tr > td > span:focus {
  color: #97310e;
  background-color: #eeeeee;
  border-color: #dddddd; }

.btn-default {
  background-image: linear-gradient(#FFF, #EFEFEF) !important;
  background-color: #f0f1f3 !important;
  color: #000 !important;
  border: 1px solid #999 !important; }

table.table tr > th a, table.table tr > th a:link, table.table tr > th a:visited {
  color: #000;
  text-decoration: underline; }

@media (min-width: 1400px) {
  .container {
    max-width: 1340px; } }

@media (min-width: 1600px) {
  .container {
    max-width: 1540px; } }

@media (min-width: 1800px) {
  .container {
    max-width: 1640px; } }

.nav-item {
  padding-left: 12px !important; }

nav .dropdown-item {
  background-color: transparent; }
  nav .dropdown-item:hover {
    background-color: #222 !important; }

nav .dropdown-menu {
  background: linear-gradient(to right, #555 0%, #666 100%); }

.breadcrumb {
  background-color: #f5f5f5;
  padding: 8px 12px; }

.navbar {
  padding: 6px 12px; }

.input-xs {
  height: 22px;
  padding: 2px 5px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px; }

.input-group .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

/*.btn-md {
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
}

.btn-lg {
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.2rem;
}*/
.form-control {
  height: calc(1.8125rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.input-group-text {
  height: calc(1.8125rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.input-group-text-xs, .input-group-xs input-group-text, .input-group-xs btn, .input-group-xs input, .btn-xs, .input-group .btn-xs {
  height: 22px;
  padding: 2px 5px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px; }

#header-container {
  color: #EAEAEA; }
  #header-container > nav.navbar {
    background-image: linear-gradient(to right, #555 0%, #666 100%);
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    text-shadow: none; }
    #header-container > nav.navbar div.navbar-header {
      padding-right: 12px; }
    #header-container > nav.navbar li.active, #header-container > nav.navbar li.active a {
      background-image: linear-gradient(to bottom, #111 0%, #333 100%);
      background-color: #999; }
      #header-container > nav.navbar li.active a, #header-container > nav.navbar li.active a:link, #header-container > nav.navbar li.active a:active, #header-container > nav.navbar li.active a:visited, #header-container > nav.navbar li.active a a, #header-container > nav.navbar li.active a a:link, #header-container > nav.navbar li.active a a:active, #header-container > nav.navbar li.active a a:visited {
        background-color: #999; }
    #header-container > nav.navbar a.navbar-brand, #header-container > nav.navbar a.navbar-brand:link, #header-container > nav.navbar a.navbar-brand:active, #header-container > nav.navbar a.navbar-brand:visited {
      color: #C5C5C5;
      text-shadow: none; }
    #header-container > nav.navbar .navbar-text, #header-container > nav.navbar li, #header-container > nav.navbar a, #header-container > nav.navbar a:link, #header-container > nav.navbar a:active, #header-container > nav.navbar a:visited {
      color: #EAEAEA;
      text-shadow: none; }
    #header-container > nav.navbar li.dropdown ul li a, #header-container > nav.navbar li.dropdown ul li a:link, #header-container > nav.navbar li.dropdown ul li a:active, #header-container > nav.navbar li.dropdown ul li a:visited {
      color: #337ab7;
      text-shadow: none; }
    #header-container > nav.navbar .login a, #header-container > nav.navbar .login a:link, #header-container > nav.navbar .login a:active, #header-container > nav.navbar .login a:visited {
      color: deepskyblue;
      text-shadow: none; }

div#sub-header-outer-container {
  margin-bottom: 10px; }

div#pnlSubTitle {
  font-size: 28px;
  line-height: 40px;
  padding: 8px 0; }
  @media screen and (max-width: 992px) {
    div#pnlSubTitle {
      font-size: 22px;
      line-height: 30px; } }

h3 {
  font-weight: 700;
  font-size: 16px;
  color: #16191f; }

div#pnlBreadcrumbs {
  margin-bottom: 10px;
  color: #000000;
  font-size: 12px;
  background-color: #FFF; }
  @media screen and (max-width: 767px) {
    div#pnlBreadcrumbs {
      position: relative;
      clear: both;
      padding: 6px 12px; } }
  div#pnlBreadcrumbs div#breadcrumbs {
    text-align: left;
    margin: 0;
    padding: 6px 0; }

@media screen and (max-width: 992px) {
  .modal-fast-auto .modal-title {
    font-size: 2rem; } }

@media screen and (max-width: 500px) {
  .modal-fast-auto .modal-title {
    font-size: 1.7rem; } }

.modal-fast-auto .modal-header {
  background-image: url("https://www.diplomaframe.com/images/noexpire/Logo_220_mobile.png");
  background-repeat: no-repeat;
  background-position: 85% center; }
  @media screen and (max-width: 500px) {
    .modal-fast-auto .modal-header {
      background-size: 150px 18px; } }
  @media screen and (max-width: 360px) {
    .modal-fast-auto .modal-header {
      background-image: none; } }

.modal-fast-auto .modal-footer {
  background-color: #f1f2f4;
  font-size: 12px;
  color: #616161;
  text-align: left;
  position: relative;
  padding: 24px; }
  .modal-fast-auto .modal-footer .confidence-text {
    text-align: left; }
  .modal-fast-auto .modal-footer div.no-find {
    position: absolute;
    right: 24px;
    top: 40%; }
    @media screen and (max-width: 992px) {
      .modal-fast-auto .modal-footer div.no-find {
        padding-left: 24px;
        padding-top: 12px;
        position: relative; } }

.modal-fast-auto #modal-search-container {
  padding: 0 24px 24px 24px;
  background-color: #f1f2f4; }
  .modal-fast-auto #modal-search-container .modal-search-box .heading-big {
    font-size: 26px;
    font-weight: bold;
    padding-top: 8px; }
  .modal-fast-auto #modal-search-container .modal-search-box .letter-box {
    text-align: left;
    padding-top: 2px; }
    .modal-fast-auto #modal-search-container .modal-search-box .letter-box > div {
      float: right; }
    @media screen and (max-width: 992px) {
      .modal-fast-auto #modal-search-container .modal-search-box .letter-box {
        padding-bottom: 24px; }
        .modal-fast-auto #modal-search-container .modal-search-box .letter-box > div {
          float: none; } }
    .modal-fast-auto #modal-search-container .modal-search-box .letter-box .letter-heading {
      color: #666668;
      font-size: 20px;
      font-weight: bold;
      padding-bottom: 4px; }
    .modal-fast-auto #modal-search-container .modal-search-box .letter-box .alpha-div {
      text-transform: uppercase;
      color: #337ab7;
      line-height: 22px;
      width: 22px;
      height: 22px;
      text-align: center;
      background-color: #FFF;
      display: inline-block;
      vertical-align: middle;
      font-weight: 700; }
      .modal-fast-auto #modal-search-container .modal-search-box .letter-box .alpha-div:hover {
        cursor: pointer;
        background-color: #D1FFFF; }
      @media screen and (max-width: 992px) {
        .modal-fast-auto #modal-search-container .modal-search-box .letter-box .alpha-div {
          margin-top: 6px; } }
  .modal-fast-auto #modal-search-container .modal-search-box .variant {
    padding-bottom: 24px; }
    .modal-fast-auto #modal-search-container .modal-search-box .variant .search-box {
      padding: 24px 0 0 0; }
      .modal-fast-auto #modal-search-container .modal-search-box .variant .search-box .input-group {
        position: relative; }
        @media screen and (max-width: 992px) {
          .modal-fast-auto #modal-search-container .modal-search-box .variant .search-box .input-group .fas {
            font-size: 1.5px; } }
        .modal-fast-auto #modal-search-container .modal-search-box .variant .search-box .input-group input {
          color: #000;
          border-radius: 0; }
          @media screen and (max-width: 992px) {
            .modal-fast-auto #modal-search-container .modal-search-box .variant .search-box .input-group input {
              font-size: 1.5px; } }
        .modal-fast-auto #modal-search-container .modal-search-box .variant .search-box .input-group .btn {
          border-radius: 0;
          background-color: #2389c7;
          padding-left: 24px;
          padding-right: 24px;
          background-image: none;
          color: #FFF;
          font-weight: 400;
          border-radius: 0;
          border-color: #2e6da4; }
          @media screen and (max-width: 992px) {
            .modal-fast-auto #modal-search-container .modal-search-box .variant .search-box .input-group .btn {
              font-size: 1.5px; } }
        .modal-fast-auto #modal-search-container .modal-search-box .variant .search-box .input-group img {
          position: absolute;
          left: 10px;
          top: 15px;
          opacity: 0.3;
          width: 14px;
          z-index: 2; }
  .modal-fast-auto #modal-search-container .modal-search-box .variant-1 .search-box .input-group .btn {
    background-color: #FFC641;
    border: none;
    color: #222;
    font-weight: 700; }
  .modal-fast-auto #modal-search-container .modal-search-box .variant-2 .search-box .input-group .btn {
    background-color: #2389c7;
    color: #FFF;
    font-weight: 400; }
  .modal-fast-auto #modal-search-container .modal-search-results table {
    font-size: 1.5px; }
    .modal-fast-auto #modal-search-container .modal-search-results table tr > td {
      padding-left: 0 12px;
      background-color: #FFF;
      color: #161616; }
      .modal-fast-auto #modal-search-container .modal-search-results table tr > td a, .modal-fast-auto #modal-search-container .modal-search-results table tr > td a:link, .modal-fast-auto #modal-search-container .modal-search-results table tr > td a:visited {
        color: #161616; }
      .modal-fast-auto #modal-search-container .modal-search-results table tr > td:hover, .modal-fast-auto #modal-search-container .modal-search-results table tr > td a:hover {
        background-color: #2389c7;
        color: #FFF;
        cursor: pointer; }
        .modal-fast-auto #modal-search-container .modal-search-results table tr > td:hover a, .modal-fast-auto #modal-search-container .modal-search-results table tr > td:hover a:link, .modal-fast-auto #modal-search-container .modal-search-results table tr > td:hover a:visited, .modal-fast-auto #modal-search-container .modal-search-results table tr > td a:hover a, .modal-fast-auto #modal-search-container .modal-search-results table tr > td a:hover a:link, .modal-fast-auto #modal-search-container .modal-search-results table tr > td a:hover a:visited {
          color: #FFF; }

@font-face {
  font-family: 'proxima nova';
  src: url("/scripts/fonts/2D8537_2_0.ttf"); }

div#sp-h {
  max-width: 1170px;
  text-align: center;
  margin: 0 auto; }
  div#sp-h h1 {
    font-size: 28px;
    font-weight: normal;
    color: #155F8E;
    font-family: 'proxima nova', 'Helvetica Neue', Helvetica, Helvetica, Arial, sans-serif;
    margin-bottom: 0; }
  div#sp-h .sub-text {
    font-size: 14px;
    color: #616161; }
  div#sp-h .grad-schools {
    line-height: 40px;
    height: 60px;
    padding-top: 12px;
    padding-bottom: 12px; }
  div#sp-h ul.dropdown-menu li {
    list-style: none; }
  div#sp-h .div-inline {
    display: inline-block; }
  div#sp-h .hidden-no-js {
    display: none; }

div#sp-v8 {
  max-width: 1170px;
  margin: 0 auto;
  font-family: 'proxima nova', 'Helvetica Neue', Helvetica, Helvetica, Arial, sans-serif; }
  div#sp-v8.p-img-current-mobile {
    max-width: 1364px; }
  div#sp-v8.p-img-current-middle {
    max-width: 1364px; }
  div#sp-v8 div#pmc {
    text-align: center; }
    div#sp-v8 div#pmc div.pc {
      padding-top: 24px;
      position: relative; }
      div#sp-v8 div#pmc div.pc div.p-img {
        vertical-align: middle;
        text-align: center;
        margin: 0 auto;
        height: 176px;
        line-height: 176px; }
        div#sp-v8 div#pmc div.pc div.p-img.p-img-current-mobile {
          height: 260px;
          line-height: 260px; }
        div#sp-v8 div#pmc div.pc div.p-img.p-img-current-middle {
          height: 260px;
          line-height: 260px; }
        div#sp-v8 div#pmc div.pc div.p-img img.p-img-old {
          max-width: 245px; }
      div#sp-v8 div#pmc div.pc img {
        max-height: 176px; }
        div#sp-v8 div#pmc div.pc img.p-img-current-mobile {
          max-height: 260px; }
        div#sp-v8 div#pmc div.pc img.p-img-current-middle {
          max-height: 260px; }
      div#sp-v8 div#pmc div.pc div.p-link {
        padding-top: 6px;
        margin: 0 auto;
        max-width: 200px; }
        div#sp-v8 div#pmc div.pc div.p-link.p-img-current-mobile {
          max-width: 300px; }
        div#sp-v8 div#pmc div.pc div.p-link.p-img-current-middle {
          max-width: 224px; }
        div#sp-v8 div#pmc div.pc div.p-link a, div#sp-v8 div#pmc div.pc div.p-link a:link, div#sp-v8 div#pmc div.pc div.p-link a:visited {
          color: #155f8e;
          text-decoration: underline; }
      div#sp-v8 div#pmc div.pc div.p-details {
        margin: 0 auto;
        /*line-height: 26px;*/
        /*height: 26px;*/
        font-size: 14px;
        padding-top: 2px;
        max-width: 200px; }
        div#sp-v8 div#pmc div.pc div.p-details.p-img-current-mobile {
          max-width: 300px; }
        div#sp-v8 div#pmc div.pc div.p-details.p-img-current-middle {
          max-width: 260px; }
        div#sp-v8 div#pmc div.pc div.p-details div:first-child {
          text-align: center; }
        div#sp-v8 div#pmc div.pc div.p-details div:last-child {
          text-align: center; }
      div#sp-v8 div#pmc div.pc .p-price {
        font-weight: 700;
        text-align: center;
        color: black; }
      div#sp-v8 div#pmc div.pc .p-item {
        color: #616161;
        font-size: 14px; }
    div#sp-v8 div#pmc > hr {
      margin: 48px 0; }
    div#sp-v8 div#pmc div#sp-bottom .images-left > div {
      text-align: center;
      padding-bottom: 24px; }
    div#sp-v8 div#pmc div#sp-bottom .html-bottom {
      font-size: 14px;
      color: #616161; }
    div#sp-v8 div#pmc div#sp-bottom > hr {
      margin: 48px 0; }
    div#sp-v8 div#pmc div#sp-bottom h2 {
      font-weight: 500;
      line-height: 1.1;
      color: #616161;
      font-size: 23px;
      margin-bottom: 10px;
      margin-top: 0;
      padding-top: 0;
      font-family: 'proxima nova', 'Helvetica Neue', Helvetica, Helvetica, Arial, sans-serif; }
  div#sp-v8 .other-info-text {
    color: #616161;
    font-size: 14px; }

div#sp-v8.debug div#pmc div.pc {
  background-color: #EAEAEA;
  border: 1px solid #555; }
  div#sp-v8.debug div#pmc div.pc div.p-img {
    background-color: #999; }
  div#sp-v8.debug div#pmc div.pc div.p-link {
    background-color: #C0C0C0; }
  div#sp-v8.debug div#pmc div.pc div.p-details {
    background-color: #888; }
  div#sp-v8.debug div#pmc div.pc .p-price {
    background-color: #555; }
  div#sp-v8.debug div#pmc div.pc .p-item {
    background-color: #999; }

/*
 * Row with equal height columns
 * --------------------------------------------------
 */
.row-eq-height {
  display: flex;
  display: -webkit-flex;
  flex-wrap: wrap; }

div.gsd-card {
  background-color: #FFF;
  border-radius: 2px;
  display: inline-block;
  position: relative;
  width: 100%;
  border-left: none;
  border-right: none;
  border-top: none;
  border-bottom: none;
  border: 1px solid #A3A3A3;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1); }
  div.gsd-card input[type="radio"] {
    margin-left: 1rem;
    margin-right: 0.25rem; }
  div.gsd-card .label {
    font-weight: 700;
    font-size: 16px;
    font-family: 'Roboto', Verdana, Arial, sans-serif; }
  div.gsd-card > div:first-child {
    font-size: 18px;
    line-height: 20px;
    background-color: #fafafa;
    border-bottom: 1px solid #EAEAEA;
    padding: 19px 20px;
    font-weight: 700;
    font-family: 'Roboto', Verdana, Arial, sans-serif; }
    @media screen and (max-width: 992px) {
      div.gsd-card > div:first-child {
        font-size: 1.6rem;
        line-height: 1.6rem;
        padding: 1.5rem 1.6rem; } }
    div.gsd-card > div:first-child.subtle {
      color: #555;
      font-size: 16px !important; }
  div.gsd-card > div:last-child {
    /*box-shadow: 0 1px 3px rgba(0,0,0,0.12);*/
    /*box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);*/
    padding: 12px; }
  div.gsd-card.gsd-card-sm > div {
    font-size: 15px; }
    div.gsd-card.gsd-card-sm > div:first-child {
      padding: 10px 11px;
      line-height: 17px;
      font-size: 18px; }
    div.gsd-card.gsd-card-sm > div .label {
      font-weight: 700;
      font-size: 15px; }
  div.gsd-card.gsd-card-xs > div {
    font-size: 12px; }
    div.gsd-card.gsd-card-xs > div:first-child {
      padding: 7px 8px;
      line-height: 14px;
      font-size: 14px; }

.gsd-card-hover:hover {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22); }

.gsd-card-1 {
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.06), 0 1px 1px rgba(0, 0, 0, 0.12); }

.gsd-card-2 {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24); }

.gsd-card-2 {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24); }

.gsd-card-2a {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23); }

.gsd-card-3 {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23); }

.gsd-card-4 {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22); }

.gsd-card-5 {
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22); }

.weborder_inc {
  background-color: purple; }

div#web-order .m-top {
  /*margin-top: $gutter/2;*/
  margin-top: 12px; }

div#web-order .m-bot {
  margin-bottom: 12px; }

div#web-order .row {
  margin-right: -12px;
  margin-left: -12px; }

div#web-order .row > div {
  padding-left: 12px;
  padding-right: 12px; }

div#web-order .web-order-heading {
  font-size: 40px;
  font-weight: 700; }
  div#web-order .web-order-heading .small, div#web-order .web-order-heading .hint {
    font-weight: 700;
    font-size: 22px; }
  div#web-order .web-order-heading .copy {
    font-size: 22px;
    cursor: copy;
    padding-left: 4px; }

div#web-order .copy {
  color: #999; }
  div#web-order .copy:hover {
    cursor: pointer;
    color: #222; }

div#web-order table.table-line-items tr > th {
  font-size: 11px; }

div#web-order table.table-line-items tr > td {
  font-size: 11px; }

div#web-order table.table-line-items .itemid {
  font-size: 14px; }

div#web-order table.table-kvp {
  margin: 0;
  padding: 0; }
  div#web-order table.table-kvp tr, div#web-order table.table-kvp tr > td {
    background-color: transparent;
    border: none;
    padding: 0;
    margin: 0;
    text-align: left; }

div#web-order table.table-custom-head > tbody > tr:first-child {
  background-image: linear-gradient(to top, #444 0%, #444 60%, #666 100%);
  font-family: 'Roboto', Verdana, Arial, sans-serif; }
  div#web-order table.table-custom-head > tbody > tr:first-child > th {
    color: #EAEAEA;
    font-size: 13px;
    border-color: #555;
    font-weight: 400;
    text-align: center;
    padding-top: 2px;
    padding-bottom: 2px; }

div#web-order table.table-custom-footer > tbody > tr:last-child {
  background-image: linear-gradient(to top, #444 0%, #444 60%, #666 100%);
  font-family: 'Roboto', Verdana, Arial, sans-serif; }
  div#web-order table.table-custom-footer > tbody > tr:last-child > td {
    color: #EAEAEA;
    font-size: 13px;
    border-color: #555;
    font-weight: 400;
    text-align: center;
    padding-top: 1px;
    padding-bottom: 1px; }

div#web-order .fa-lock {
  color: #222; }

div#web-order table.table-address td {
  margin: 0;
  padding: 2px 4px; }

div#web-order table.table-order-info tr td:first-child {
  text-align: right;
  font-weight: 700; }

div#web-order table.table-order-info tr td:not(:first-child) {
  text-align: left;
  padding-left: 6px; }

div#web-order .dotted-border {
  border: 1px dotted #999; }

div#web-order .m-left-6 {
  margin-left: 6px; }

div#web-order .fa-14 {
  font-size: 14px; }

div#web-order .border-sides {
  border-left: 1px solid #A3A3A3;
  border-right: 1px solid #A3A3A3; }

div#web-order .border-bottom {
  border-bottom: 1px solid #A3A3A3 !important; }

div#web-order table.table-order-history tr td:first-child {
  width: 160px; }

div#web-order table.table-order-history tr td:last-child {
  white-space: normal;
  word-break: break-all; }

div#web-order table.table-tech tr td:first-child {
  width: 140px; }

.orders-inc {
  margin-top: 12px; }
  .orders-inc .m-bot {
    margin-bottom: 12px; }

div#template-editor body {
  background-color: #EAEAEA; }

div#template-editor .container {
  max-width: 1400px !important; }

div#template-editor .mce-branding {
  display: none !important; }

div#template-editor table.document {
  background-color: #FFF; }
  div#template-editor table.document td:first-child {
    text-align: right;
    vertical-align: middle;
    font-weight: 400;
    padding-right: 12px;
    width: 200px; }

div#template-editor .col-edit {
  background-color: #999;
  position: relative;
  min-height: 40px; }
  div#template-editor .col-edit > div {
    background-color: #FFF;
    /*min-height: 26px;*/ }

div#template-editor .col-setting {
  position: absolute;
  top: 0;
  right: 0;
  color: #444;
  background-color: transparent !important; }
  div#template-editor .col-setting i {
    font-size: 20px;
    background-color: transparent !important; }
    div#template-editor .col-setting i:hover {
      cursor: pointer;
      color: #007bff;
      box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19); }

div#template-editor .col-settings-open {
  right: 16px; }

div#template-editor span.new-col:hover {
  color: #007bff;
  cursor: pointer;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.06); }

div#template-editor table.log tr > td:last-child {
  width: 60%;
  white-space: normal;
  word-break: break-all; }

div#mm-bs3 {
  font-family: proxima nova,Helvetica Neue,Helvetica,Arial,sans-serif;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1); }
  div#mm-bs3 p {
    margin: 0; }
  @media (min-width: 768px) {
    div#mm-bs3 {
      /*.dropdown .dropdown-menu {
            transition: all 0.5s;
            display: block;
            overflow: hidden;
            opacity: 0;
        }

        .dropdown.open .dropdown-menu {
            opacity: 1;
        }*/ }
      div#mm-bs3 div.navbar {
        margin: 0;
        min-height: inherit;
        padding: 0 5px; }
        div#mm-bs3 div.navbar ul.navbar-mm {
          float: none;
          display: flex;
          justify-content: space-between;
          align-content: space-between;
          flex-wrap: nowrap;
          align-items: flex-start;
          position: relative;
          flex-direction: row;
          width: 100%; }
          div#mm-bs3 div.navbar ul.navbar-mm:before, div#mm-bs3 div.navbar ul.navbar-mm:after {
            display: none; }
          div#mm-bs3 div.navbar ul.navbar-mm > li {
            float: none;
            align-self: center; }
      div#mm-bs3 div.navbar ul.navbar-mm > li.dropdown {
        position: static; }
        div#mm-bs3 div.navbar ul.navbar-mm > li.dropdown > a, div#mm-bs3 div.navbar ul.navbar-mm > li.dropdown > a:link, div#mm-bs3 div.navbar ul.navbar-mm > li.dropdown > a:hover {
          color: #002f87;
          font-size: 18px;
          font-weight: 400;
          background-color: #FFF; }
        div#mm-bs3 div.navbar ul.navbar-mm > li.dropdown > a {
          padding: 5px 0;
          padding-bottom: 2px; }
          div#mm-bs3 div.navbar ul.navbar-mm > li.dropdown > a:hover {
            color: darkred; }
        div#mm-bs3 div.navbar ul.navbar-mm > li.dropdown.open > a, div#mm-bs3 div.navbar ul.navbar-mm > li.dropdown.open > a:link, div#mm-bs3 div.navbar ul.navbar-mm > li.dropdown.open > a:hover {
          border-bottom: 2px solid #606C79;
          padding-bottom: 0; }
        div#mm-bs3 div.navbar ul.navbar-mm > li.dropdown > div.dropdown-menu {
          right: 0;
          border: 1px solid #606C79;
          box-shadow: none;
          box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
          border-radius: 0; }
          div#mm-bs3 div.navbar ul.navbar-mm > li.dropdown > div.dropdown-menu > * {
            white-space: normal; }
        div#mm-bs3 div.navbar ul.navbar-mm > li.dropdown:not(:first-child) > a {
          padding-left: 12px; }
        div#mm-bs3 div.navbar ul.navbar-mm > li.dropdown:not(:last-child) > a {
          padding-right: 12px; }
      div#mm-bs3 .navbar-collapse {
        padding: 0; } }
  div#mm-bs3 .navbar-toggle:hover {
    background-color: #EAEAEA; }
  @media (max-width: 768px) {
    div#mm-bs3 div.navbar {
      margin: 0;
      padding: 0; }
      div#mm-bs3 div.navbar .icon-bar {
        background-color: #888; }
      div#mm-bs3 div.navbar ul.navbar-mm > li.dropdown {
        border-bottom: 1px solid #EAEAEA; }
        div#mm-bs3 div.navbar ul.navbar-mm > li.dropdown > a, div#mm-bs3 div.navbar ul.navbar-mm > li.dropdown > a:link, div#mm-bs3 div.navbar ul.navbar-mm > li.dropdown > a:hover {
          color: #036;
          padding-top: 10px;
          padding-bottom: 10px;
          line-height: 20px;
          font-size: 16px; }
        div#mm-bs3 div.navbar ul.navbar-mm > li.dropdown:first-child {
          border-top: 1px solid #EAEAEA; } }

html {
  height: 100%; }

body {
  font-family: Verdana, sans-serif;
  font-size: 14px;
  min-height: 100%;
  position: relative;
  margin: 0;
  padding-bottom: 80px;
  background-color: #f2f3f3; }

h1, h2, h3, h4, div#pnlSubTitle, div.g-card-heading, .navbar-brand, .roboto {
  font-family: 'Roboto', Verdana, Arial, sans-serif; }

h4 {
  font-size: 18px; }

.footer {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 24px;
  text-align: center; }

.color-primary {
  color: #337ab7; }

.bg-color-primary {
  background-color: #337ab7; }

.color-inherit, a.color-inherit, a.color-inherit:link, a.color-inherit:visited {
  color: inherit; }

.relative {
  position: relative; }

.color-red {
  color: darkred; }

.gutter-margin-top {
  margin-top: 24px; }

.gutter-margin-left {
  margin-left: 24px; }

.gutter-margin-right {
  margin-right: 24px; }

.gutter-margin-bottom {
  margin-bottom: 24px; }

.gutter-margin-top-md {
  margin-top: 0; }
  @media screen and (max-width: 992px) {
    .gutter-margin-top-md {
      margin-top: 24px; } }

.gutter-top, .gutter-top-md {
  padding-top: 24px; }

.gutter-left {
  padding-left: 24px; }

.gutter-right {
  padding-right: 24px; }

.gutter-bottom {
  padding-bottom: 24px; }

.gutter-top-md {
  margin-top: 0; }
  @media screen and (max-width: 992px) {
    .gutter-top-md {
      margin-top: 24px; } }

.pad-form {
  padding: 12px; }

.pad-top {
  padding-top: 6px; }

.pad-bottom {
  padding-bottom: 6px; }

.pad-left {
  padding-left: 12px; }

.pad-right {
  padding-right: 12px; }

.pad-top-2x {
  padding-top: 12px; }

.pad-bottom-2x {
  padding-bottom: 12px; }

.pad-left-2x {
  padding-left: 24px; }

.pad-right-2x {
  padding-right: 24px; }

.pad-top-4x {
  padding-top: 24px; }

.pad-bottom-4x {
  padding-bottom: 24px; }

.pad-left-4x {
  padding-left: 48px; }

.pad-right-4x {
  padding-right: 48px; }

.margin-top {
  margin-top: 6px; }

.margin-bottom {
  margin-bottom: 6px; }

.margin-left {
  margin-left: 12px; }

.margin-right {
  margin-right: 12px; }

.margin-top-2x {
  margin-top: 12px; }

.margin-bottom-2x {
  margin-bottom: 12px; }

.margin-left-2x {
  margin-left: 24px; }

.margin-right-2x {
  margin-right: 24px; }

.margin-top-4x {
  margin-top: 24px; }

.margin-bottom-4x {
  margin-bottom: 24px; }

.margin-left-4x {
  margin-left: 48px; }

.margin-right-4x {
  margin-right: 48px; }

.margin-center {
  margin: 0 auto; }

.pointer {
  cursor: pointer; }

.b, .bold {
  font-weight: 700 !important; }

.n {
  font-weight: 400; }

.small, .hint {
  font-size: 11px; }

.no-border {
  border: none !important;
  border-width: 0 !important; }

.hint {
  color: #888;
  font-weight: 400; }

.xs {
  font-size: 9px; }

.g-card {
  background: #fff;
  border-radius: 2px;
  /*display: inline-block;*/
  position: relative;
  width: 100%;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1); }
  .g-card .g-card-header, .g-card .g-card-heading {
    font-size: 16px;
    line-height: 16px;
    background-color: #fafafa;
    border-bottom: 1px solid #eaeded;
    padding: 12px 14px;
    font-weight: 700; }
    .g-card .g-card-header.g-card-header-lg, .g-card .g-card-header.g-card-heading-lg, .g-card .g-card-heading.g-card-header-lg, .g-card .g-card-heading.g-card-heading-lg {
      font-size: 15px;
      line-height: 15px; }
    .g-card .g-card-header *.g-card-header-sm, .g-card .g-card-header.g-card-heading-sm, .g-card .g-card-header.g-card-heading-small, .g-card .g-card-heading *.g-card-header-sm, .g-card .g-card-heading.g-card-heading-sm, .g-card .g-card-heading.g-card-heading-small {
      font-size: 14px;
      padding: 4px 12px 2px 12px;
      line-height: 24px; }
    @media screen and (max-width: 992px) {
      .g-card .g-card-header, .g-card .g-card-heading {
        font-size: 15px;
        line-height: 15px; } }
  .g-card .g-card-content.g-card-content-padded {
    padding: 12px 12px; }

.login-form > div {
  padding-bottom: 12px; }
  .login-form > div > div {
    line-height: 34px; }
    @media screen and (max-width: 767px) {
      .login-form > div > div {
        line-height: inherit; } }
    .login-form > div > div > div:first-child {
      font-weight: 700;
      font-size: 14px;
      text-align: right;
      margin: 0;
      padding: 0;
      padding-right: 12px; }
      @media screen and (max-width: 767px) {
        .login-form > div > div > div:first-child {
          text-align: left; } }
    .login-form > div > div > div:last-child {
      margin: 0;
      padding: 0;
      text-align: left; }

.zero {
  margin: 0;
  padding: 0; }
  .zero.important {
    margin: 0 !important;
    padding: 0 !important; }

.zeroPad, .pad0 {
  padding: 0; }

.zeroMargin, .margin0 {
  margin: 0; }

.hide {
  display: none; }

.show {
  display: block; }

.box {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24); }

.box-heavy {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23); }

.g-card-hover:hover {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22); }

.g-card-1, div#pnlBreadcrumbs, .modal-fast-auto #modal-search-container .modal-search-box .letter-box .alpha-div {
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.06), 0 1px 1px rgba(0, 0, 0, 0.12); }

.g-card-2, .modal-fast-auto #modal-search-container .modal-search-box .letter-box .alpha-div:hover, .modal-fast-auto #modal-search-container .modal-search-results {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24); }

.g-card-2, .modal-fast-auto #modal-search-container .modal-search-box .letter-box .alpha-div:hover, .modal-fast-auto #modal-search-container .modal-search-results {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24); }

.g-card-2a {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23); }

.g-card-3 {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23); }

.g-card-4 {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22); }

.g-card-5 {
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22); }

.hr-gray {
  width: 200px;
  background-color: gray;
  height: 1px;
  margin-top: 2px; }

.border-square {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border-radius: 0 !important; }

table.table-striped-alt tr:nth-child(odd):not(:first-child) {
  background-image: linear-gradient(to left, #FFF 50%, #F5F5F5); }

table td.wrap, div.wrap, .wrap {
  word-wrap: break-word !important;
  white-space: normal !important; }

table.table-pad-sides td:first-child, table.table-pad-sides th:first-child {
  padding-left: 12px; }

table.table-pad-sides td:last-child, table.table-pad-sides th:last-child {
  padding-right: 12px; }

.color-red {
  color: red; }

.color-darkred {
  color: darkred; }

.color-darkgreen {
  color: darkgreen; }

.color-black {
  color: #000; }

.color-gray {
  color: #999; }

.toast-top-full-width {
  margin-top: 5%; }

table.table-rbl tr {
  background-color: transparent !important; }

table.table-rbl td {
  padding: 4px 8px;
  border: none; }
  table.table-rbl td label {
    padding: 0;
    margin: 0;
    padding-left: 4px; }

table.table td, table.table th {
  padding: 5px; }

.f9 {
  font-size: 9px; }

.f10 {
  font-size: 10px; }

.f11 {
  font-size: 11px; }

.f12 {
  font-size: 12px; }

.f13 {
  font-size: 13px; }

.f14 {
  font-size: 14px; }

.f15 {
  font-size: 15px; }

.f16 {
  font-size: 16px; }

.f17 {
  font-size: 17px; }

.f18 {
  font-size: 18px; }

.f19 {
  font-size: 19px; }

.f20 {
  font-size: 20px; }

.f21 {
  font-size: 21px; }

.f22 {
  font-size: 22px; }

.f23 {
  font-size: 23px; }

.f24 {
  font-size: 24px; }

.f25 {
  font-size: 25px; }

.f26 {
  font-size: 26px; }

.f27 {
  font-size: 27px; }

.f28 {
  font-size: 28px; }

table.table-sticky-header th {
  background-color: #FFF;
  color: #222; }

.m-top {
  margin-top: 24px; }
  .m-top.important {
    margin-top: 24px !important; }

.m-bot {
  margin-bottom: 24px; }
  .m-bot.important {
    margin-bottom: 24px !important; }

.resp {
  max-width: 100%;
  height: auto; }
