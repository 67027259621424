﻿@import './_variables.scss';

div#template-editor {
    body {
        background-color: #EAEAEA;
    }

    .container {
        max-width: 1400px !important;
    }

    .mce-branding {
        display: none !important;
    }

    table.document {
        background-color: #FFF;

        td:first-child {
            text-align: right;
            vertical-align: middle;
            font-weight: 400;
            padding-right: 12px;
            width: 200px;
        }
    }

    .col-edit {
        background-color: #999;
        position: relative;
        min-height: 40px;

        > div {
            background-color: #FFF;
            /*min-height: 26px;*/
        }
    }

    $setting-icon-size: 20px;

    .col-setting {
        position: absolute;
        top: 0;
        right: 0;
        color: #444;
        background-color: transparent !important;

        i {
            font-size: $setting-icon-size;
            background-color: transparent !important;

            &:hover {
                cursor: pointer;
                color: #007bff;
                box-shadow: 0 10px 20px rgba(0,0,0,0.19);
            }
        }
    }

    .col-settings-open {
        right: 16px;
    }


    span.new-col:hover {
        color: #007bff;
        cursor: pointer;
        text-shadow: 0 1px 2px rgba(0,0,0,0.06);
    }

    table.log {
        tr > td:last-child {
            width: 60%;
            white-space: normal;
            word-break: break-all;
        }
    }
}