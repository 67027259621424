﻿@import 'variables';


$letter-square: 22px;

.modal-fast-auto {
    .modal-title {
        @media screen and (max-width: $size-md) {
            font-size: 2rem;
        }

        @media screen and (max-width: 500px) {
            font-size: 1.7rem;
        }
    }

    .modal-header {
        background-image: url('https://www.diplomaframe.com/images/noexpire/Logo_220_mobile.png');
        background-repeat: no-repeat;
        background-position: 85% center;

        @media screen and (max-width: 500px) {
            background-size: 150px 18px;
        }

        @media screen and (max-width: 360px) {
            background-image: none;
        }
    }

    .modal-footer {
        background-color: rgb(241, 242, 244);
        font-size: 12px;
        color: rgb(97, 97, 97);
        text-align: left;
        position: relative;
        padding: $gutter;

        .confidence-text {
            text-align: left;
        }

        div.no-find {
            position: absolute;
            right: $gutter;
            top: 40%;

            @media screen and (max-width: $size-md) {
                padding-left: $gutter;
                padding-top: $gutter/2;
                position: relative;
            }
        }
    }

    #modal-search-container {
        padding: 0 $gutter $gutter $gutter;
        background-color: rgb(241, 242, 244);

        .modal-search-box {
            .heading-big {
                font-size: 26px;
                font-weight: bold;
                padding-top: 8px;
            }

            .letter-box {
                text-align: left;
                padding-top: 2px;

                > div {
                    float: right;
                }

                @media screen and (max-width: $size-md) {
                    padding-bottom: $gutter;

                    > div {
                        float: none;
                    }
                }



                .letter-heading {
                    color: #666668;
                    font-size: 20px;
                    font-weight: bold;
                    padding-bottom: 4px;
                }

                .alpha-div {
                    @extend .g-card-1;
                    text-transform: uppercase;
                    color: #337ab7;
                    line-height: $letter-square;
                    width: $letter-square;
                    height: $letter-square;
                    text-align: center;
                    background-color: #FFF;
                    display: inline-block;
                    vertical-align: middle;
                    font-weight: 700;

                    &:hover {
                        @extend .g-card-2;
                        cursor: pointer;
                        background-color: #D1FFFF;
                    }

                    @media screen and (max-width: $size-md) {
                        margin-top: 6px;
                    }
                }
            }

            .variant {
                padding-bottom: $gutter;

                .search-box {
                    padding: $gutter 0 0 0;

                    .input-group {
                        position: relative;

                        .fas {
                            @media screen and (max-width: $size-md) {
                                font-size: 1.5px;
                            }
                        }


                        input {
                            color: #000;
                            border-radius: 0;

                            @media screen and (max-width: $size-md) {
                                font-size: 1.5px;
                            }
                        }

                        .btn {
                            border-radius: 0;
                            background-color: #2389c7;
                            padding-left: $gutter;
                            padding-right: $gutter;
                            background-image: none;
                            color: #FFF;
                            font-weight: 400;
                            border-radius: 0;
                            border-color: #2e6da4;

                            @media screen and (max-width: $size-md) {
                                font-size: 1.5px;
                            }
                        }

                        img {
                            position: absolute;
                            left: 10px;
                            top: 15px;
                            opacity: 0.3;
                            width: 14px;
                            z-index: 2;
                        }
                    }
                }
            }

            .variant-1 {
                .search-box {
                    .input-group {
                        .btn {
                            background-color: #FFC641;
                            border: none;
                            color: #222;
                            font-weight: 700;
                        }
                    }
                }
            }

            .variant-2 {
                .search-box {
                    .input-group {
                        .btn {
                            background-color: rgb(35, 137, 199);
                            color: #FFF;
                            font-weight: 400;
                        }
                    }
                }
            }
        }

        .modal-search-results {
            @extend .g-card-2;

            table {
                font-size: 1.5px;

                tr {
                    > td {
                        padding-left: 0 $gutter/2;
                        background-color: #FFF;
                        color: rgb(22,22,22);

                        a, a:link, a:visited {
                            color: rgb(22,22,22);
                        }


                        &:hover, a:hover {
                            background-color: rgb(35, 137, 199);
                            color: #FFF;
                            cursor: pointer;

                            a, a:link, a:visited {
                                color: #FFF;
                            }
                        }
                    }
                }
            }
        }
    }
}