@import 'variables';
@import 'vendor-extend';
@import 'bootstrap-extend';
@import 'layout';
@import 'contentSearchBox';
@import 'storepagev8';
@import './card.scss';
@import './pages/weborder/_weborder.scss';
@import './_tinymce.scss';

@import './example/_mainmenu-bs3.scss';

html{
    height:100%;
}

body {
    font-family: Verdana, sans-serif;
    font-size: 14px;
    min-height: 100%;
    position: relative;
    margin: 0;
    padding-bottom: 80px;
    background-color: #f2f3f3;
}
h1, h2, h3, h4, div#pnlSubTitle, div.g-card-heading, .navbar-brand, .roboto {
    font-family: 'Roboto', Verdana, Arial, sans-serif;
}

h4{
    font-size:18px;
}

.footer {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    padding: $gutter;
    text-align: center;
}

.color-primary {
    color: $color-primary;
}
.bg-color-primary {
    background-color: $color-primary;
}
.color-inherit, a.color-inherit, a.color-inherit:link, a.color-inherit:visited{
    color: inherit;
}
.relative{
    position:relative;
}
.color-red{
    color:darkred;
}

.gutter-margin-top {margin-top: $gutter;}
.gutter-margin-left {margin-left: $gutter;}
.gutter-margin-right {margin-right: $gutter;}
.gutter-margin-bottom {margin-bottom: $gutter;}


.gutter-margin-top-md {
    margin-top:0;
    @media screen and (max-width: $size-md) {
        margin-top: $gutter;
    }
}

.gutter-top {padding-top: $gutter;}
.gutter-left {padding-left: $gutter;}
.gutter-right {padding-right: $gutter;}
.gutter-bottom {padding-bottom: $gutter;}

.gutter-top-md {
    @extend .gutter-top;
    margin-top: 0;

    @media screen and (max-width: $size-md) {
        margin-top: $gutter;
    }
}


.pad-form {padding:$gutter/2}

.pad-top { padding-top:$gutter/4; }
.pad-bottom { padding-bottom: $gutter/4; }
.pad-left { padding-left: $gutter/2; }
.pad-right { padding-right: $gutter/2; }

.pad-top-2x { padding-top:$gutter/2; }
.pad-bottom-2x { padding-bottom: $gutter/2; }
.pad-left-2x { padding-left: $gutter; }
.pad-right-2x { padding-right: $gutter; }

.pad-top-4x { padding-top:$gutter; }
.pad-bottom-4x { padding-bottom: $gutter; }
.pad-left-4x { padding-left: $gutter*2; }
.pad-right-4x { padding-right: $gutter*2; }

.margin-top { margin-top:$gutter/4; }
.margin-bottom { margin-bottom: $gutter/4; }
.margin-left { margin-left: $gutter/2; }
.margin-right { margin-right: $gutter/2; }

.margin-top-2x { margin-top:$gutter/2; }
.margin-bottom-2x { margin-bottom: $gutter/2; }
.margin-left-2x { margin-left: $gutter; }
.margin-right-2x { margin-right: $gutter; } 

.margin-top-4x { margin-top:$gutter; }
.margin-bottom-4x { margin-bottom: $gutter; }
.margin-left-4x { margin-left: $gutter*2; }
.margin-right-4x { margin-right: $gutter*2; }

.margin-center{
    margin: 0 auto;
}

.pointer{
    cursor:pointer;
}

.b,.bold{
    font-weight: 700 !important;
}
.n{
    font-weight: 400;
}

.small{
    font-size:11px;
}

.no-border{
    border:none !important;
    border-width:0 !important;
}


.hint{
    @extend .small;
    color:#888;
    font-weight:400;
}
.xs{
    font-size:9px;
}

.g-card {
    background: #fff;
    border-radius: 2px;
    /*display: inline-block;*/
    position: relative;
    width: 100%;

    .g-card-header, .g-card-heading {
        font-size: 16px;
        line-height: 16px;
        background-color: #fafafa;
        border-bottom: 1px solid #eaeded;
        padding: 12px 14px;
        font-weight: 700;

        &.g-card-header-lg, &.g-card-heading-lg {
            font-size: 15px;
            line-height: 15px;
        }

        *.g-card-header-sm, &.g-card-heading-sm, &.g-card-heading-small {
            font-size: 14px;
            padding: 4px 12px 2px 12px;
            line-height: 24px;
        }

        @media screen and (max-width: $size-md) {
            font-size: 15px;
            line-height: 15px;
        }
    }

    .g-card-content {
        &.g-card-content-padded {
            padding: $gutterForm $gutterForm;
        }
    }

    transition: all 0.3s cubic-bezier(.25,.8,.25,1);
}

.login-form {
    > div{
            padding-bottom: $gutterForm;
        > div {
            line-height: 34px;

            @media screen and (max-width: $size-xs) {
                line-height: inherit;
            }

            > div:first-child {
                font-weight: 700;
                font-size: 14px;
                text-align: right;
                margin: 0;
                padding: 0;
                padding-right: $gutterForm;

                @media screen and (max-width: $size-xs) {
                    text-align: left;
                }
            }

            > div:last-child {
                margin: 0;
                padding: 0;
                text-align: left;
            }
        }
    }
}

.zero {
    margin: 0;
    padding: 0;

    &.important {
        margin: 0 !important;
        padding: 0 !important;
    }
}
.zeroPad,.pad0 { padding:0; }
.zeroMargin, .margin0 { margin:0; }
.hide { display: none; }
.show { display: block; }
.box { box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24); }
.box-heavy { box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23); }
.g-card-hover:hover { box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22); }
.g-card-1 { box-shadow: 0 1px 2px rgba(0,0,0,0.06), 0 1px 1px rgba(0,0,0,0.12); }
.g-card-2 { box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24); }
.g-card-2 { box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24); }
.g-card-2a { box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23); }
.g-card-3 { box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23); }
.g-card-4 { box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22); }
.g-card-5 { box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22); }


.hr-gray {
    width: 200px; 
    background-color: gray; 
    height: 1px;
    margin-top:2px;
}

.border-square {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    border-radius: 0 !important;
}

table.table-striped-alt tr:nth-child(odd):not(:first-child) {
    background-image: linear-gradient(to left,#FFF 50%,#F5F5F5);
}

table td.wrap, div.wrap, .wrap {
    word-wrap: break-word !important;
    white-space: normal !important;
}

table.table-pad-sides td:first-child, table.table-pad-sides th:first-child {
    padding-left: $gutter/2;
}

table.table-pad-sides td:last-child, table.table-pad-sides th:last-child {
    padding-right: $gutter/2;
}



.color-red {
    color: red;
}
.color-darkred{
    color:darkred;
}
.color-darkgreen {
    color: darkgreen;
}
.color-black{
    color: #000;
}
.color-gray{
    color:#999;
}

.toast-top-full-width{
    margin-top:5%;
}

table.table-rbl {
    tr {
        background-color: transparent !important;
    }

    td {
        padding: 4px 8px;
        border:none;
        label {
            padding: 0;
            margin: 0;
            padding-left: 4px;
        }
    }
}


table.table td, table.table th{
    padding: 5px;
}


.f9{font-size:9px;}
.f10{font-size:10px;}
.f11{font-size:11px;}
.f12{font-size:12px;}
.f13{font-size:13px;}
.f14{font-size:14px;}
.f15{font-size:15px;}
.f16{font-size:16px;}
.f17{font-size:17px;}
.f18{font-size:18px;}
.f19{font-size:19px;}
.f20{font-size:20px;}
.f21{font-size:21px;}
.f22{font-size:22px;}
.f23{font-size:23px;}
.f24{font-size:24px;}
.f25{font-size:25px;}
.f26{font-size:26px;}
.f27{font-size:27px;}
.f28{font-size:28px;}

table.table-sticky-header th{
    background-color:#FFF;
    color:#222;
}

.m-top {
    margin-top: $gutter;

    &.important {
        margin-top: $gutter !important;
    }
}
.m-bot {
    margin-bottom: $gutter;

    &.important {
        margin-bottom: $gutter !important;
    }
}
.resp{
    max-width:100%;
    height:auto;
}