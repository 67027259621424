﻿div#mm-bs3 {
    font-family: proxima nova,Helvetica Neue,Helvetica,Arial,sans-serif;
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);

    p{
        margin:0;
    }

    @media (min-width: 768px) {
        div.navbar {
            margin: 0;
            min-height: inherit;
            padding: 0 5px;

            ul.navbar-mm {
                float: none;
                display: flex;
                justify-content: space-between;
                align-content: space-between;
                flex-wrap: nowrap;
                align-items: flex-start;
                position: relative;
                flex-direction: row;
                width: 100%;

                &:before, &:after {
                    display: none;
                }

                > li {
                    float: none;
                    align-self: center;
                }
            }
        }

        div.navbar ul.navbar-mm > li.dropdown {
            position: static;

            > a, > a:link, > a:hover {
                color: #002f87;
                font-size: 18px;
                font-weight: 400;
                background-color: #FFF;
            }

            > a {
                &:hover {
                    color: rgb(139,0,0);
                }

                padding: 5px 0;
                padding-bottom: 2px;
            }

            &.open {
                > a, > a:link, > a:hover {
                    border-bottom: 2px solid #606C79;
                    padding-bottom: 0;
                }
            }

            > div.dropdown-menu {
                right: 0;
                border: 1px solid #606C79;
                box-shadow: none;
                box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
                border-radius: 0;

                > * {
                    white-space: normal;
                }
            }

            &:not(:first-child) > a {
                padding-left: 12px;
            }

            &:not(:last-child) > a {
                padding-right: 12px;
            }
        }

        .navbar-collapse {
            padding: 0;
        }
        /*.dropdown .dropdown-menu {
            transition: all 0.5s;
            display: block;
            overflow: hidden;
            opacity: 0;
        }

        .dropdown.open .dropdown-menu {
            opacity: 1;
        }*/
    }

    .navbar-toggle:hover {
        background-color: #EAEAEA;
    }

    @media (max-width: 768px) {
        div.navbar {
            margin: 0;
            padding: 0;

            .icon-bar {
                background-color: #888;
            }

            ul.navbar-mm > li.dropdown {
                > a, > a:link, > a:hover {
                    color: #036;
                    padding-top: 10px;
                    padding-bottom: 10px;
                    line-height: 20px;
                    font-size: 16px;
                }

                border-bottom: 1px solid #EAEAEA;

                &:first-child {
                    border-top: 1px solid #EAEAEA;
                }
            }
        }
    }
}