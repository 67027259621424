﻿.awesomplete {
    display:block;
    font-family: "proxima nova", "Helvetica Neue", Helvetica, Helvetica, Arial, sans-serif
}
.awesomplete > ul {
    background: hsla(0,0%,100%,.95);
}

.awesomplete > ul > li {
    color: rgb(34, 34, 34);
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    font-size:16px;
}

.awesomplete > ul > li[aria-selected="true"] {
    background: none;
    font-weight: inherit;
    color: #000;
    background-color: #EAEAEA;
}

.awesomplete mark {
    background: none;
    font-weight: 700;
    padding:0;
}

.awesomplete li[aria-selected="true"] mark {
    background: none;
    font-weight: 700;
}

.awesomplete > ul > li:hover, .awesomplete li:hover mark {
    background: none;
    background-color: #EAEAEA;
    color: #000;
}

/* reinstitute initial .form-control border radius when nested within .awesomeplete */
.input-group .awesomplete .form-control {
    border-radius: 4px;
}
/* reinstitute .border-radius squaring within form groups */
.input-group .awesomplete:first-child .form-control {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.input-group .awesomplete:not(:first-child) .form-control {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.input-group .awesomplete:not(:first-child):not(:last-child) .form-control {
    border-radius: 0;
}

.input-group-lg .awesomplete .form-control {
    height: 46px;
    padding: 10px 16px;
    font-size: 18px;
    line-height: 1.3333333;
}

/**
 * bootstrap's input groups modify the float & z-index of form-controls, this allows awesomplete
 * to assume the position of the form-control and reset the input
 */
.input-group div.awesomplete {
    position: relative;
    z-index: 2;
    float: left;
    width: 100%;
    margin-bottom: 0;
}

.input-group div.awesomplete > input {
    float: none;
}

.input-group div.awesomplete > ul {
    z-index: 3; /* bootstrap sets .input-group .form-control to 2 */
}


.badge-lg {
    font-size: 14px;
    font-family: 'Roboto', Verdana, Arial, sans-serif;
    padding: 8px 16px;
}

#toast-container > div {
    opacity: 0.975;
}

table.dxeReadOnly_MaterialCompact, table.dxeReadOnly_MaterialCompact input, table.dxeReadOnly_MaterialCompact textarea, table.dxeReadOnly_MaterialCompact select {
    background-color: #ccc !important;
}

div.dxmLite_MaterialCompact.dxm-ltr > div {
    padding: 4px 12px !important;
}
div.dxgvSearchPanel_MaterialCompact{
    padding: 6px 12px !important; 
}

.tooltip-inner {
    text-align: left;
}