﻿@import "_variables.scss";

#header-container {
    color: $headerColor;

    > nav.navbar {
        background-image: linear-gradient(to right, #555 0%, #666 100%);
        box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
        text-shadow: none;

        div.navbar-header {
            padding-right: $gutterForm;
        }

        li.active, li.active a {
            background-image: linear-gradient(to bottom, #111 0%, #333 100%);
            background-color: #999;

            a, a:link, a:active, a:visited {
                background-color: #999;
            }
        }

        a.navbar-brand, a.navbar-brand:link, a.navbar-brand:active, a.navbar-brand:visited {
            color: $headerBrandColor;
            text-shadow: none;
        }

        .navbar-text, li, a, a:link, a:active, a:visited {
            color: $headerColor;
            text-shadow: none;
        }

        li.dropdown ul li {
            a, a:link, a:active, a:visited {
                color: #337ab7;
                text-shadow: none;
            }
        }

        .login {
            a, a:link, a:active, a:visited {
                color: deepskyblue;
                text-shadow: none;
            }
        }
    }
}

div#sub-header-outer-container {
    margin-bottom:10px;
}
div#pnlSubTitle {
    font-size: 28px;
    line-height: 40px;
    padding: $gutter/3 0;

    @media screen and (max-width: $size-md) {
        font-size: 22px;
        line-height: 30px;
    }
}

h3 {
    font-weight: 700;
    font-size: 16px;
    color: #16191f;
}

div#pnlBreadcrumbs {
    @extend .g-card-1;
    margin-bottom: 10px;
    color: $bodyColor;
    font-size: 12px;
    background-color: #FFF;

    @media screen and (max-width: $size-xs) {
        position: relative;
        clear: both;
        padding: $gutterForm/2 $gutterForm;
    }

    div#breadcrumbs {
        text-align: left;
        margin: 0;
        padding: $gutterForm/2 0;
    }
}