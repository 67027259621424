﻿$gutter:24px;
$gutterForm:$gutter/2;

$headerColor: #EAEAEA;
$headerBrandColor: #C5C5C5;
$headerHeight:42px;


$size-xs: 767px;
$size-sm: 768px;
$size-md: 992px;
$size-lg: 1200px;
$size-xl: 1480px;
$size-xxl: 1840px;

$color-primary: #337ab7;

$bodyColor: #000000;
$bodyBackgroundColor: #FFF;