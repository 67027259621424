﻿@import './_variables.scss';

$store-page-v8-product-container-max-width: 200px;
$store-page-v8-product-container-height: 176px;

$store-page-v8-product-container-max-width-current-mobile: 300px;
$store-page-v8-product-container-height-current-mobile: 260px;

$store-page-v8-product-container-max-width-current-middle: 260px;
$store-page-v8-product-container-height-current-middle: 260px;


@font-face {
    font-family: 'proxima nova';
    src: url('/scripts/fonts/2D8537_2_0.ttf');
}

div#sp-h {
    max-width:1170px;
    text-align: center;
    margin:0 auto;

    h1{
        font-size: 28px;
        font-weight: normal;
        color: #155F8E;
        font-family: 'proxima nova', 'Helvetica Neue', Helvetica, Helvetica, Arial, sans-serif;
        margin-bottom: 0;
    }
    .sub-text{
        font-size:14px;
        color:rgb(97, 97, 97);
    }
    .grad-schools{
        line-height: 40px;
        height:$gutter*2.5;
        padding-top:$gutter/2;
        padding-bottom: $gutter/2;
    }

    ul.dropdown-menu li{
        list-style:none;
    }

    .div-inline{
        display:inline-block;
    }

    .hidden-no-js{
        display: none;
    }
}

div#sp-v8 {
    max-width: 1170px;
    margin: 0 auto;
    font-family: 'proxima nova', 'Helvetica Neue', Helvetica, Helvetica, Arial, sans-serif;

    &.p-img-current-mobile {
        max-width: 1364px;
    }

    &.p-img-current-middle {
        max-width: 1364px;
    }

    div#pmc {
        text-align: center;

        div.pc {
            padding-top: $gutter;
            position: relative;

            div.p-img {
                vertical-align: middle;
                text-align: center;
                margin: 0 auto;
                height: $store-page-v8-product-container-height;
                line-height: $store-page-v8-product-container-height;

                &.p-img-current-mobile {
                    height: $store-page-v8-product-container-height-current-mobile;
                    line-height: $store-page-v8-product-container-height-current-mobile;
                }

                &.p-img-current-middle {
                    height: $store-page-v8-product-container-height-current-middle;
                    line-height: $store-page-v8-product-container-height-current-middle;
                }

                img.p-img-old {
                    max-width: 245px;
                }
            }

            img {
                max-height: $store-page-v8-product-container-height;

                &.p-img-current-mobile {
                    max-height: $store-page-v8-product-container-height-current-mobile;
                }

                &.p-img-current-middle {
                    max-height: $store-page-v8-product-container-height-current-middle;
                }
            }

            div.p-link {
                padding-top: $gutter/4;
                margin: 0 auto;
                max-width: $store-page-v8-product-container-max-width;

                &.p-img-current-mobile {
                    max-width: $store-page-v8-product-container-max-width-current-mobile;
                }

                &.p-img-current-middle {
                    max-width: 224px;
                }

                a, a:link, a:visited {
                    color: rgb(21, 95, 142);
                    text-decoration: underline;
                }
            }

            div.p-details {
                margin: 0 auto;
                /*line-height: 26px;*/
                /*height: 26px;*/
                font-size: 14px;
                padding-top: 2px;
                max-width: $store-page-v8-product-container-max-width;

                &.p-img-current-mobile {
                    max-width: $store-page-v8-product-container-max-width-current-mobile;
                }

                &.p-img-current-middle {
                    max-width: $store-page-v8-product-container-max-width-current-middle;
                }

                div:first-child {
                    text-align: center;
                }

                div:last-child {
                    text-align: center;
                }
            }

            .p-price {
                font-weight: 700;
                text-align: center;
                color: rgb(0, 0, 0);
            }

            .p-item {
                color: rgb(97, 97, 97);
                font-size: 14px;
            }
        }

        > hr {
            margin: $gutter*2 0;
        }

        div#sp-bottom {
            .images-left > div {
                text-align: center;
                padding-bottom: $gutter;
            }

            .html-bottom {
                font-size: 14px;
                color: rgb(97, 97, 97);
            }

            > hr {
                margin: $gutter*2 0;
            }

            h2 {
                font-weight: 500;
                line-height: 1.1;
                color: rgb(97, 97, 97);
                font-size: 23px;
                margin-bottom: 10px;
                margin-top: 0;
                padding-top: 0;
                font-family: 'proxima nova', 'Helvetica Neue', Helvetica, Helvetica, Arial, sans-serif;
            }
        }
    }

    .other-info-text {
        color: rgb(97, 97, 97);
        font-size: 14px;
    }
}


div#sp-v8.debug {
    div#pmc {
        div.pc {
            background-color:#EAEAEA;
            border: 1px solid #555;

            div.p-img{
                background-color:#999;
            }

            div.p-link{
                background-color:#C0C0C0;
            }

            div.p-details{
                background-color:#888;
            }

            .p-price{
                background-color:#555;
            }

            .p-item{
                background-color:#999;
            }
        }
    }
}


/*
 * Row with equal height columns
 * --------------------------------------------------
 */
 .row-eq-height {
    display: flex;
    display: -webkit-flex;
    flex-wrap: wrap;
  }