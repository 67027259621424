﻿@import './_variables.scss';

div.gsd-card {
    background-color: #FFF;
    border-radius: 2px;
    display: inline-block;
    position: relative;
    width: 100%;
    border-left: none;
    border-right: none;
    border-top: none;
    border-bottom: none;
    border: 1px solid #A3A3A3;

    input[type="radio"] {
        margin-left: 1rem;
        margin-right: 0.25rem;
    }

    .label {
        font-weight: 700;
        font-size: 16px;
        font-family: 'Roboto', Verdana, Arial, sans-serif;
    }

    > div:first-child {
        font-size: 18px;
        line-height: 20px;
        background-color: #fafafa;
        border-bottom: 1px solid #EAEAEA;
        padding: 19px 20px;
        font-weight: 700;
        font-family: 'Roboto', Verdana, Arial, sans-serif;

        @media screen and (max-width: 992px) {
            font-size: 1.6rem;
            line-height: 1.6rem;
            padding: 1.5rem 1.6rem;
        }

        &.subtle {
            color: #555;
            font-size: 16px !important;
        }
    }

    > div:last-child {
        /*box-shadow: 0 1px 3px rgba(0,0,0,0.12);*/
        /*box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);*/
        padding: $gutter/2;
    }

    &.gsd-card-sm {
        > div {
            font-size: 15px;

            &:first-child {
                padding: 10px 11px;
                line-height: 17px;
                font-size: 18px;
            }

            .label {
                font-weight: 700;
                font-size: 15px;
            }
        }
    }

    &.gsd-card-xs {
        > div {
            font-size: 12px;

            &:first-child {
                padding: 7px 8px;
                line-height: 14px;
                font-size: 14px;
            }
        }
    }

    transition: all 0.3s cubic-bezier(.25,.8,.25,1);
}

.gsd-card-hover:hover {
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
}

.gsd-card-1 {
    box-shadow: 0 1px 2px rgba(0,0,0,0.06), 0 1px 1px rgba(0,0,0,0.12);
}

.gsd-card-2 {
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
}

.gsd-card-2 {
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
}

.gsd-card-2a {
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
}

.gsd-card-3 {
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
}

.gsd-card-4 {
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
}

.gsd-card-5 {
    box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
}